<template>
  <div class="h-100">
    <DevBar v-if="toggleDevBar"></DevBar>

    <transition name="inner" mode="out-in">
      <b-container fluid v-if="isLoadingApp">
        <div id="ContainerLoading" class="text-center">
          <b-img class="w-50 mb-5" center :src="PUBLIC_PATH + 'img/wuro.svg'"></b-img>
          <div class="margin-auto flex">
            <b-spinner class="mr-2" small variant="primary" label="Spinning"></b-spinner>
            <b-progress class="w-100" height="7px" :value="loadingPercent"></b-progress>
          </div>
        </div>
      </b-container>
      <component v-else :is="this.$route.meta.layout || 'div'">
        <transition name="inner" mode="out-in">
          <router-view :key="componentKey" v-if="ifComponentKey"/>
        </transition>
      </component>
    </transition>
  </div>
</template>

<script>
// Libs && Helpers
import {mapGetters, mapActions} from 'vuex'
import DevBar from './components/DevBar'

const DESCRIPTION = "Wuro est un logiciel de facturation et de gestion d'entreprise en ligne. Il permet à tous les entrepreneurs de créer des factures et des devis, de communiquer avec ses collaborateurs et de suivre son chiffre d'affaires"

export default {
  name: 'app',
  data () {
    return {
      layout: `div`,
      PUBLIC_PATH: process.env.BASE_URL
    }
  },
  components: {
    DevBar
  },
  computed: {
    ...mapGetters(['isLoadingApp', 'isAuthenticated', 'loadingPercent']),
    componentKey () {
      let key = 'componentView'

      if (this.$route.params.company_url && this.currentPosition) {
        key += '_' + this.currentPosition.company.url
      }

      return key
    },
    ifComponentKey () {
      if (!this.$route.params.company_url) return true
      if (this.$route.params.company_url && !this.currentPosition) return true
      if (this.$route.params.company_url && this.currentPosition && this.$route.params.company_url === this.currentPosition.company.url) return true

      return false
    },
    toggleDevBar () {
      return !(process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production')
    }
  },
  created: async function () {
    this.createChatbot()

    window.addEventListener('beforeunload', this.handleClose)
  },
  methods: {
    ...mapActions(['$a_stopLoadingAppInfos', '$a_loadUserDatas', '$a_logout', '$a_loadCompanyDatas']),
    createChatbot () {
      var script = document.createElement('script')
      script.id = 'fullwidget'
      script.src = 'https://chatbot-wuro.awelty.com:3020/js/fullwidget.js'
      document.body.appendChild(script)
    },
    handleClose () {
      //this.$socket.emit('UNREGISTER', this.$socket.id)
    }
  }
}
</script>

<style>
#ContainerLoading {
  width: 30%;
  margin: auto;
  margin-top: 20%;
}

#ProgressDev {
  border-radius: 0;
}

.pop-enter-active, .pop-leave-active {
  transition: all .6s ease;
}

.pop-enter, .pop-leave-to {
  opacity: 0;
}

.inner-enter-active, .inner-leave-active {
  transition: all .10s linear;
}
.inner-leave-to {
  opacity: 0;
}
.inner-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all .5s linear;
}
.fade-leave-to {
  opacity: 0;
}
.fade-enter {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}

.rotateY-leave-active,
.rotateY-enter-active {
  transition: 0.4s;
}
.rotateY-enter {
  transform: rotateY(-90deg);
}
.rotateY-leave-to {
  transform: rotateY(90deg);

}

.rotateY-leave-active,
.rotateY-enter-active {
  transition: 0.4s;
}
.rotateY-enter {
  transform: rotateY(-90deg);
}
.rotateY-leave-to {
  transform: rotateY(90deg);

}

</style>
